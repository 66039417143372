import React, { Component } from 'react';
import $ from 'jquery';

import { ajax_wrapper } from 'functions';
import { NewProject, ProjectCard } from 'components';
import { Container, Loading, Button, Modal, Select } from 'library';

function focus_on_page() {
    setTimeout(function () {
        var x = window.scrollX,
            y = window.scrollY;
        $('.song-page-container').focus();
        window.scrollTo(x, y);
    }, 100);
}

export default class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            projects: [],
            admin_projects: [],
            admin_selected_client: '',
            show_project_create: false,
            tagger: { name: '' },
        };
    }

    componentDidMount() {
        this.refresh_projects();
    }

    refresh_projects = () => {
        ajax_wrapper('GET', `/api/my_projects/`, {}, (value) =>
            this.setState({
                loaded: true,
                projects: value['projects'],
                admin_projects: value['admin_projects'],
                tagger: value['tagger'],
                admin: value['admin'],
            }),
        );
    };

    render() {
        let projects = [];
        let tagger_name = this.state.tagger['name'];

        if (this.state.projects.length + this.state.admin_projects.length > 0) {
            let new_project_button = (
                <div
                    className="simple-card project-card flex-container"
                    style={{ width: '80px' }}
                >
                    <Button
                        type="primary"
                        onClick={() =>
                            this.setState({ show_project_create: true })
                        }
                        className="dark-button new-project-button"
                    >
                        +
                    </Button>
                </div>
            );
            projects.push(new_project_button);
        }

        if (!this.state.admin) {
            for (let item of this.state.projects) {
                projects.push(
                    <ProjectCard
                        data={item}
                        tagger={this.state.tagger}
                        admin={false}
                    />,
                );
            }
        } else {
            for (let item of this.state.admin_projects) {
                if (
                    item.client == this.state.admin_selected_client ||
                    !this.state.admin_selected_client
                ) {
                    projects.push(
                        <ProjectCard
                            data={item}
                            tagger={this.state.tagger}
                            admin={true}
                        />,
                    );
                }
            }
        }

        // TODO: change below card to not show for non-admins

        if (projects.length === 0) {
            projects.push(
                <div style={{ paddingBottom: '10px' }}>
                    <h3>You have no active projects!</h3>
                    <i>{'—> Select from the following —> '}</i>
                    <br />
                    <br />
                    <Button
                        type="primary"
                        className="dark-button"
                        onClick={() =>
                            this.setState({ show_project_create: true })
                        }
                    >
                        Create a new project
                    </Button>
                    <br />
                    <Button
                        type="primary"
                        className="dark-button"
                        href="#admin-projects"
                    >
                        View active projects
                    </Button>
                    <br />
                    <Button
                        type="primary"
                        className="dark-button"
                        href="admin/manage_taggers"
                    >
                        Manage Taggers
                    </Button>
                    <br />
                </div>,
            );
        }

        let projects_jsx = [];

        if (!this.state.admin) {
            projects_jsx.push(
                <div id="tagger-projects">
                    <h1>Active Projects - {tagger_name}</h1>
                    <div className="simple-card-container row">{projects}</div>
                    <br />
                </div>,
            );
        } else {
            projects_jsx.push(
                <div id="admin-projects">
                    <div
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'center',
                        }}
                    >
                        <h1
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'center',
                            }}
                        >
                            All Projects (Admin)
                        </h1>
                        <Select
                            placeholder="Filter by Client"
                            name="admin_selected_client"
                            className="transparent-select"
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'top',
                            }}
                            options_url={'/api/home/client/'}
                            set_form_state={(state) => this.setState(state)}
                        />
                    </div>

                    <div className="simple-card-container row">{projects}</div>
                </div>,
            );
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div>
                        <Modal
                            show={this.state.show_project_create}
                            on_hide={() =>
                                this.setState(
                                    {
                                        show_project_create: false,
                                    },
                                    focus_on_page,
                                )
                            }
                        >
                            <div className="simple-card-container modal-bounding-box">
                                <div className="simple-card">
                                    <NewProject />
                                </div>
                            </div>
                        </Modal>

                        <div>{projects_jsx}</div>
                    </div>
                </Loading>
            </Container>
        );
    }
}
