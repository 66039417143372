function swap_dict(json) {
    var ret = {};
    for (let key in json) {
        ret[json[key]] = key;
    }
    return ret;
}

function create_dict_from_listofdict(json, key_name, item_name) {
    // note: no validation for duplicate keys, etc., use at own risk
    var ret = {};
    for (let dict of json) {
        let key = dict[key_name];
        let item = dict[item_name];
        ret[key] = item;
    }
    return ret;
}

export { swap_dict, create_dict_from_listofdict };
