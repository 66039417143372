import React, { Component } from 'react';

import { Button, Image } from 'library';
import { export_project } from 'functions';

export default class ProjectCard extends Component {
    render() {
        let project_data = this.props.data;
        let split_jsx = [];
        let split_count = project_data['splits'].length;

        for (let split of project_data['splits']) {
            let tagger_in_split = false;
            for (let tagger of split.taggers) {
                if (tagger.id === this.props.tagger.id) {
                    tagger_in_split = true;
                    break;
                }
            }

            if (split_count > 1) {
                if (this.props.admin || tagger_in_split) {
                    split_jsx.push(
                        <Button
                            className="split-display-flag"
                            href={`/project/${project_data['id']}/${split['split_number']}/`}
                        >
                            Split {split['split_number']}
                        </Button>,
                    );
                }
            }
            if (this.props.admin) {
                for (let tagger of split['taggers']) {
                    split_jsx.push(
                        <span className="multiselect-selection">
                            {tagger.name}
                        </span>,
                    );
                }
                split_jsx.push(<br />);
            }
        }

        let split_status_jsx = [];

        split_status_jsx.push(
            <div className="project-card-text" style={{ fontWeight: 'bold' }}>
                Total Tracks: {project_data['split_songs_summary'][0]['total']}
                <br />
            </div>,
        );

        for (let split of project_data['splits']) {
            let split_number = split['split_number'];
            let split_summary =
                project_data['split_songs_summary'][split_number];

            if (project_data['splits'].length === 1) {
                split_status_jsx.push(
                    <div className="project-card-text">
                        Tracks Complete: {split_summary['complete']}
                        <br />
                        Tracks Remaining:{' '}
                        {split_summary['total'] - split_summary['complete']}
                    </div>,
                );
            } else {
                split_status_jsx.push(
                    <div className="project-card-text">
                        Split {split_number} Tracks remaining:{' '}
                        {split_summary['total'] - split_summary['complete']}
                    </div>,
                );
            }
        }

        return (
            <div className="col-3">
                <div className="simple-card project-card ">
                    <h4>{project_data['name']}</h4>
                    <p className="project-card-subtitle">
                        Client: {project_data['client_name']}
                    </p>

                    {split_jsx}
                    {split_status_jsx}
                    <br />
                    <Button
                        type="primary"
                        href={`/project/${project_data['id']}/${0}/`}
                    >
                        View
                    </Button>

                    <Image
                        src="/static/images/download-icon-medium-white.png"
                        className="project-download-button"
                        onClick={() => {
                            export_project(
                                project_data['id'],
                                project_data['export_format'],
                            );
                        }}
                    />
                </div>
            </div>
        );
    }
}
