import React, { Component } from 'react';

import {
    Container,
    Loading,
    Button,
    DeleteButton,
    Dropdown,
    SortableList,
} from 'library';
import {
    ajax_wrapper,
    get_global_state,
    sort_objects,
    toast_handler,
    export_project,
} from 'functions';
import { SongCard, DynamicSearch } from 'components';
import $, { param } from 'jquery';

function focus_on_page() {
    setTimeout(function () {
        var x = window.scrollX,
            y = window.scrollY;
        $('.project-page-container').focus();
        window.scrollTo(x, y);
    }, 100);
}

export default class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: {},
            songs_lookup: {},
            song_tags: {},
            editing_track_id: '',
            sync_status: 'Getting Sync Status...',
            show_search: false,
            search_type: '',
            search_type_id: '',
            tag_types: [],
            tag_lookup: {},
            filter_threshold: 100,
            filter_active: true,
            include_empty: true,
            show_all: true,
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];
        if (!('split' in params)) {
            params['split'] = 0;
        }

        this.intervalId = setInterval(() => {
            if (
                this.state.sync_status !== 'Synced' &&
                this.state.sync_status !== ''
            ) {
                this.updateSyncStatus();
            }
        }, 2000);

        ajax_wrapper(
            'GET',
            `/api/project_details/${params['id']}/${params['split']}/`,
            {},
            (value) => {
                let songs_lookup = {};
                let song_tags = {};
                let index = 0;
                for (let song of value.songs) {
                    songs_lookup[song.id] = song;
                    song_tags[song.id] = song.tags;
                    index += 1;
                }
                this.setState(
                    {
                        loaded: true,
                        data: value,
                        songs_lookup: songs_lookup,
                        split_number: params['split'],
                    },
                    this.load_tags(value['taxonomy_tags'], value['tag_types']),
                );
            },
        );
    }

    show_hide_all = () => {
        this.setState({ show_all: this.state.show_all ? false : true });
    };

    updateSyncStatus = () => {
        let params = get_global_state()['params'];
        ajax_wrapper(
            'GET',
            `/api/get_sync_status/${params['id']}/`,
            {},
            (response) => {
                let sync_status = '';

                if (response.status === 'No Running Syncs') {
                    // Tracks already synced don't need indicator
                    // Tracks left on the syncing page will display
                    // 'Synced' when finished
                    if (this.state.sync_status === 'Getting Sync Status...') {
                        sync_status = '';
                    } else {
                        sync_status = 'Synced';
                    }
                } else {
                    sync_status = response.status;
                }

                this.setState({ sync_status: sync_status });
            },
        );
    };

    delete_project = () => {
        ajax_wrapper(
            'POST',
            `/api/delete_project/${this.state.data.id}/`,
            {},
            (value) => (window.location = `/`),
        );
    };

    load_tags = (value, split_tag_types) => {
        let tag_lookup = {};
        let tag_types = [];

        let tag_type_ids = [];
        for (let tagtype of split_tag_types) {
            tag_type_ids.push(tagtype.id);
        }

        for (let tagtype of value) {
            if (tag_type_ids.includes(tagtype.id)) {
                tag_types.push(tagtype);

                for (let item of tagtype['tags']) {
                    tag_lookup[item['id']] = item;
                    for (let child of item['children']) {
                        tag_lookup[child['id']] = child;
                    }
                }
            }
        }

        this.setState({
            tag_types: tag_types,
            tag_lookup: tag_lookup,
        });
    };

    toggle_song_tag = (id, song_id) => {
        let data = {
            split_number: this.state.split_number,
            add: [],
            remove: [],
        };
        let tag = this.state.tag_lookup[id];
        let tag_type = null;

        for (let type of this.state.tag_types) {
            if (type.id === tag['tag_type']) {
                tag_type = type;
                break;
            }
        }

        let tags = [...this.state.songs_lookup[song_id].tags];
        let found_tag = null;
        let current_tags_of_type = [];

        for (let item of tags) {
            if (item['id'] == id) {
                found_tag = tags.indexOf(item);
            }
            if (item['tag_type'] === tag_type.id) {
                current_tags_of_type.push(item);
            }
        }

        if (found_tag == null) {
            if (current_tags_of_type.length < tag_type['max']) {
                data['add'].push(id);
                tags.push(this.state.tag_lookup[id]);
            }
        } else {
            data['remove'].push(id);
            tags.splice(found_tag, 1);
        }

        tags = sort_objects(tags, ['name']);

        let new_songs_lookup = { ...this.state.songs_lookup };

        // setting in both places in case songs_lookup tags get reused somewhere
        new_songs_lookup[song_id].tags = tags;

        this.setState(
            {
                songs_lookup: new_songs_lookup,
            },
            () =>
                ajax_wrapper(
                    'POST',
                    `/api/toggle_song_tags/${song_id}/`,
                    data,
                    (value) => {
                        new_songs_lookup = { ...this.state.songs_lookup };
                        new_songs_lookup[song_id].status_by_split =
                            value.status_by_split;
                        this.setState({ songs_lookup: new_songs_lookup });
                    },
                ),
        );
    };

    show_search = (type_id, search_type, track_id, track_tags) => {
        this.setState({
            search_type: search_type,
            search_type_id: type_id,
            editing_track_id: track_id,
            show_search: true,
        });
    };

    onDragEnd = (result, reordered_items, callback) => {
        console.log(
            `${reordered_items[result.source.index].id} moved to ${
                result.source.index
            } :: ${reordered_items[result.destination.index].id} moved to ${
                result.destination.index
            }`,
        );

        let songs_lookup = Object.assign({}, this.state.songs_lookup);

        songs_lookup[reordered_items[result.source.index].id].order =
            result.source.index;
        songs_lookup[reordered_items[result.destination.index].id].order =
            result.destination.index;

        this.setState({ songs_lookup: songs_lookup }, callback);

        let data = {};
        data[reordered_items[result.source.index].id] = result.source.index;
        data[reordered_items[result.destination.index].id] =
            result.destination.index;

        ajax_wrapper('POST', '/api/update_songs_order/', data, function () {});
    };

    render() {
        let params = get_global_state()['params'];
        let sync_status = '';
        if (this.state.sync_status !== '') {
            sync_status = (
                <div>
                    <h5
                        style={{
                            display: 'inline-block',
                            fontStyle: 'italic',
                            fontWeight: 'lighter',
                        }}
                    >
                        Sync Status:
                    </h5>
                    <p
                        style={{
                            display: 'inline-block',
                            fontWeight: 'light',
                            marginLeft: '10px',
                        }}
                    >
                        {this.state.sync_status}
                    </p>
                </div>
            );
        }

        let songs = [];
        let to_be_tagged = 0;
        let in_progress = 0;
        let complete = 0;

        let index = 0;

        let song_list = [];
        for (let key in this.state.songs_lookup) {
            song_list.push(this.state.songs_lookup[key]);
        }
        song_list = sort_objects(song_list, ['order']);

        for (let song of song_list) {
            let song_status = song.status_by_split[this.state.split_number];
            if (song_status === 'Complete') {
                complete++;
            } else if (song_status === 'In Progress') {
                in_progress++;
            } else {
                to_be_tagged++;
            }

            // calculate minimum tag confidence of ML tags
            let ml_confidence_ratings = [];
            for (let tag of this.state.songs_lookup[song.id].tags) {
                if (tag.ml_generated) {
                    ml_confidence_ratings.push(tag.ml_confidence);
                } else {
                    ml_confidence_ratings.push(1);
                }
            }
            let song_ml_confidence = Math.min(...ml_confidence_ratings);
            if (
                (ml_confidence_ratings.length === 0 &&
                    this.state.include_empty) |
                (song_ml_confidence <= this.state.filter_threshold / 100)
            ) {
                songs.push({
                    id: song.id,
                    content: (
                        <SongCard
                            id={song.id}
                            data={song}
                            split_number={this.state.split_number}
                            song_url={song['dropbox_url']}
                            editable={true}
                            show={this.state.show_all}
                            tag_types={this.state.tag_types}
                            tags={this.state.songs_lookup[song.id].tags}
                            show_search={this.show_search}
                            toggle_tag={(id) =>
                                this.toggle_song_tag(id, song.id)
                            }
                            style={{ margin: '0px' }}
                        />
                    ),
                });
            }

            index += 1;
        }

        let export_options = [];
        if (this.state.data['export_formats']) {
            for (let format of this.state.data['export_formats']) {
                export_options.push(
                    <Button
                        type="outline-primary"
                        onClick={() => {
                            export_project(params['id'], format['id']);
                        }}
                        style={{ width: '100%' }}
                    >
                        {format['name']}
                    </Button>,
                );
            }
        }

        let splits_display = [];

        return (
            <div className="project-page-container">
                <Container>
                    <Loading loaded={this.state.loaded}>
                        <DynamicSearch
                            show={this.state.show_search}
                            tag_type_name={this.state.search_type}
                            tag_type={this.state.search_type_id}
                            data={this.state.data}
                            project_view={true}
                            existing_tags={
                                this.state.editing_track_id
                                    ? this.state.songs_lookup[
                                          this.state.editing_track_id
                                      ].tags
                                    : null
                            }
                            tag_types={this.state.tag_types}
                            tag_lookup={this.state.tag_lookup}
                            dropdown_categories={
                                this.state.data['dropdown_categories']
                            }
                            toggle_tag={(id) =>
                                this.toggle_song_tag(
                                    id,
                                    this.state.editing_track_id,
                                )
                            }
                            on_hide={() =>
                                this.setState(
                                    {
                                        show_search: false,
                                        search_type: '',
                                        search_type_id: '',
                                    },
                                    focus_on_page,
                                )
                            }
                        />

                        <div className="simple-card-container">
                            <div className="simple-card">
                                <div>
                                    <DeleteButton
                                        className="project-button"
                                        type="danger"
                                        url="/api/delete_project/"
                                        callback={() => (window.location = '/')}
                                        value={this.state.data.id}
                                    >
                                        Delete Project
                                    </DeleteButton>
                                    <div
                                        className="project-button"
                                        style={{ marginRight: '10px' }}
                                    >
                                        <Dropdown
                                            style={{ textAlign: 'center' }}
                                        >
                                            {export_options}
                                        </Dropdown>
                                    </div>
                                    <Button
                                        style={{ marginRight: '10px' }}
                                        className="project-button"
                                        type="primary"
                                        href={`/project/${params['id']}/add_songs/`}
                                    >
                                        Add Songs
                                    </Button>
                                </div>
                                <div>
                                    <h2
                                        style={{
                                            marginRight: '20px',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {this.state.data.name}:
                                    </h2>
                                    <h3 style={{ display: 'inline-block' }}>
                                        {songs.length} total tracks
                                    </h3>
                                    <div
                                        className="split-display-flag"
                                        style={{
                                            display: 'inline-block',
                                            marginLeft: '10px',
                                        }}
                                    >
                                        {this.state.data['split_view'] == 'Main'
                                            ? ''
                                            : this.state.data['split_view']}
                                    </div>
                                    {sync_status}
                                </div>
                                <table
                                    style={{
                                        width: '20%',
                                        display: 'inline-block',
                                        borderSpacing: '15px 2px',
                                        borderCollapse: 'separate',
                                    }}
                                >
                                    <tr>
                                        <td>
                                            <h5>To Be Tagged:</h5>
                                        </td>
                                        <td>{to_be_tagged} tracks</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>In Progress:</h5>
                                        </td>
                                        <td>{in_progress} tracks</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>Complete:</h5>
                                        </td>
                                        <td>{complete} tracks</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="simple-card">
                                <h3>Project Notes:</h3>
                                <p>{this.state.data.project_notes}</p>
                            </div>
                            <div className="simple-card">
                                <Button
                                    className="project-button"
                                    type="primary"
                                    style={{
                                        backgroundColor:
                                            'rgba(20, 40, 100, .5)',
                                        float: 'right',
                                        marginTop: '12px',
                                    }}
                                    onClick={this.show_hide_all}
                                >
                                    {this.state.show_all
                                        ? 'Collapse '
                                        : 'Show '}{' '}
                                    all tracks
                                </Button>
                                <table
                                    style={{
                                        borderSpacing: '12px',
                                        borderCollapse: 'separate',
                                    }}
                                >
                                    <tr>
                                        <td
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                        >
                                            ML Confidence:
                                        </td>
                                        <td
                                            style={{
                                                verticalAlign: 'middle',
                                                paddingTop: '8px',
                                            }}
                                        >
                                            <input
                                                type="range"
                                                min="0"
                                                max="100"
                                                value={
                                                    this.state.filter_threshold
                                                }
                                                style={{}}
                                                class="slider"
                                                id="myRange"
                                                onInput={(e) =>
                                                    this.setState({
                                                        filter_threshold:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </td>
                                        <td
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                        >
                                            Tags less than{' '}
                                            {this.state.filter_threshold}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Include Empty:</td>

                                        <td>
                                            <input
                                                type="checkbox"
                                                style={{
                                                    verticalAlign: 'middle',
                                                    paddingTop: '8px',
                                                }}
                                                checked={
                                                    this.state.include_empty
                                                        ? 'checked'
                                                        : null
                                                }
                                                onClick={() =>
                                                    this.setState({
                                                        include_empty:
                                                            !this.state
                                                                .include_empty,
                                                    })
                                                }
                                            />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div className="simple-card-container">
                            <SortableList
                                loaded={this.state.loaded}
                                drag_class="simple-card"
                                items={songs}
                                onDragEnd={this.onDragEnd}
                            />
                        </div>
                    </Loading>
                </Container>
            </div>
        );
    }
}
